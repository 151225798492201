import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Home } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const GoHomeButtonStyled = styled(Button)(({ theme }) => ({
  position: 'fixed',
  top: 20,
  left: 20,
  backgroundColor: '#7a3eea',
  color: '#fff',
  borderRadius: 24,
  border: 'none',
  padding: '0.5rem 1.5rem',
  fontSize: '1rem',
  fontWeight: 500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem', /* Espacio entre el icono y el texto */
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',

  '&:hover': {
    backgroundColor: '#a06be4',
    boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.15)',
  },

  '&:focus': {
    outline: 'none',
  },
}));

const GoHomeButton = () => {
  const navigate = useNavigate();

  return (
    <GoHomeButtonStyled onClick={() => navigate('/')}>
      <Home sx={{ fontSize: '1.2rem' }} /> {/* Icono de Home */}
      Go Home
    </GoHomeButtonStyled>
  );
};

export default GoHomeButton;
