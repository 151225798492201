// SaveApiKey.js
import React, { useState } from 'react';
import {
  Typography,
  Button,
  TextField,
  Grid2,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGlobal } from '../context/globalContex';

const SaveApiKey = () => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { state, saveUserApiKey } = useGlobal(); // Get the function saveUserApiKey from the global context
  const userId = state.user?._id; // Get the userId from the global state

  const [apiKey, setApiKey] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  const handleSaveApiKey = async (event) => {
    event.preventDefault();

    if (!apiKey) {
      setSnackbar({
        open: true,
        message: 'Please enter your API Key',
        severity: 'error',
      });
      return;
    }

    setLoading(true);
    try {
      await saveUserApiKey(userId, apiKey);
      setSnackbar({
        open: true,
        message: 'API Key saved successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error saving the API Key',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div
      style={{
        padding: '2rem',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Grid2 container justifyContent="center" style={{ marginBottom: '2rem' }}>
        <Grid2 item xs={12} sm={10} md={8} lg={6} xl={5}>
          <Typography
            variant="h4"
            style={{ color: theme.palette.primary.main }}
          >
            Enter your OpenAI API Key
          </Typography>
          <Typography
            variant="body1"
            style={{ color: theme.palette.text.secondary }}
          >
            If you already have your API Key, enter it in the field below. If
            not, follow the detailed instructions to create one.
          </Typography>

          {/* Form to save the API Key */}
          <form onSubmit={handleSaveApiKey}>
            <TextField
              type="password"
              name="apiKey"
              label="API Key"
              placeholder="API Key"
              autoComplete="new-password"
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.primary,
                border: `1px solid ${theme.palette.divider}`,
                marginBottom: '1rem',
                borderRadius: '8px',
                '& .MuiInputBase-input': {
                  color: theme.palette.text.primary,
                  padding: '1rem',
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.divider,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              required
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.buttonText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              {loading ? 'Saving...' : 'Save API Key'}
            </Button>
          </form>
        </Grid2>
      </Grid2>

      {/* Instructions to get an API Key */}
      <Grid2 container justifyContent="center">
        <Grid2 item xs={12} sm={10} md={8} lg={6} xl={5}>
          <Typography
            variant="h5"
            style={{ color: theme.palette.text.primary }}
          >
            Don't have an OpenAI API Key?
          </Typography>
          <Typography
            variant="body1"
            style={{ color: theme.palette.text.secondary }}
          >
            Follow these steps to get your API Key:
          </Typography>

          <ol
            style={{
              color: theme.palette.text.primary,
              lineHeight: '1.8',
            }}
          >
            <li>
              Visit the OpenAI website at{' '}
              <a
                href="https://platform.openai.com/signup"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.palette.primary.main }}
              >
                Sign up at OpenAI
              </a>{' '}
              or{' '}
              <a
                href="https://platform.openai.com/login"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.palette.primary.main }}
              >
                Log in
              </a>{' '}
              if you already have an account.
            </li>
            <li>
              Access the <strong>Dashboard</strong> and click on your username
              in the top right corner.
            </li>
            <li>
              In the dropdown menu, select <strong>"View API Keys"</strong>.
            </li>
            <li>
              Click on <strong>"Create new secret key"</strong> to generate a
              new API Key.
            </li>
            <li>
              Copy your new API Key and paste it into the field above.
            </li>
            <li>
              Click on <strong>"Save API Key"</strong> to start using the
              platform.
            </li>
          </ol>
        </Grid2>
      </Grid2>

      {/* Snackbar to display messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SaveApiKey;
