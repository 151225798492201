import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const GoBackButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: 20,
  left: 20,
  backgroundColor: '#7a3eea',
  color: 'white',
  borderRadius: '50%',
  border: 'none',
  fontSize: '1.5rem',
  '&:hover': {
    backgroundColor: '#a06be4',
  },
}));

const GoBackButton = () => {
  const navigate = useNavigate();

  return (
    <GoBackButtonStyled
      onClick={() => navigate(-1)}  // Volver a la página anterior
    >
      <ArrowBack fontSize="inherit" />
    </GoBackButtonStyled>
  );
};

export default GoBackButton;
