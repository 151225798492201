import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Box, CircularProgress, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { QRCode as QRCodeGenerator } from 'react-qrcode-logo';
import { useTheme } from '@mui/material/styles';
import { useGlobal } from '../context/globalContex';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkSessionStatus, setAutonomousMode, getQRCode } from '../services/client';
import SessionService from '../services/session';
import GoHomeButton from './goHomeButton';
import PendingResponses from './pendingResponse';

const ConfigQR = () => {
  const [showQRCode, setShowQRCode] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [product, setProduct] = useState('');
  const [sessionData, setSessionData] = useState(null);
  const [isAutonomous, setIsAutonomous] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);
  
  const theme = useTheme();
  const { logoutUser, state } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();
  const location = useLocation();
  const userSessionId = location.state?.userSessionId;

  useEffect(() => {
    const checkSession = async () => {
      if (userSessionId) {
        try {
          const session = await checkSessionStatus(userSessionId);
          if (session) {
            setSessionData(session);
            setIsAuthenticated(session.isAuthenticated || false);
            setIsAutonomous(session.isAutonomous || false);
          }
        } catch (error) {
          console.error('Error fetching session:', error);
        }
      }
    };
    checkSession();
  }, [userSessionId]);

  const handleModeChange = async () => {
    try {
      const newMode = !isAutonomous;
      await setAutonomousMode(userSessionId, newMode);
      setIsAutonomous(newMode);
      setSnackbar({ open: true, message: `Autonomous mode ${newMode ? 'enabled' : 'disabled'}.`, severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error changing mode.', severity: 'error' });
      console.error('Error changing mode:', error);
    }
  };

  const handleSendMasive = () => {
    if (user.activeSus) {
      navigate(`/sendWsMasive`, { state: { userSessionId } });
    } else {
      setPremiumDialogOpen(true);
    }
  };

  const handleSubmit = async () => {
    if (!companyName || !product) {
      setSnackbar({ open: true, message: 'Please provide company name and product details.', severity: 'error' });
      return;
    }

    setLoading(true);
    try {
      const sessionData = { userId: userSessionId, empresa: companyName, product: product };
      await SessionService.createSession(sessionData);
      setSnackbar({ open: true, message: 'Session created successfully.', severity: 'success' });

      const qrData = await getQRCode(userSessionId);
      console.log("qrData", qrData)
      if (qrData && qrData.qr) {
        setQrCodeData(qrData.qr);
        setShowQRCode(true);
        setSnackbar({ open: true, message: 'QR code generated successfully!', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Error generating QR code.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error creating session or generating QR:', error);
      setSnackbar({ open: true, message: 'Error creating session or generating QR code.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      setSnackbar({ open: true, message: 'Successfully logged out.', severity: 'success' });
      navigate('/');
    } catch (error) {
      setSnackbar({ open: true, message: 'Error logging out. Please try again.', severity: 'error' });
      console.error('Error in handleLogout:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePremiumDialogClose = () => {
    setPremiumDialogOpen(false);
  };

  const handlePremiumActionClick = () => {
    if (user.hasUsedFreeTrial) {
      navigate('/subscribe');
    } else {
      navigate('/freeTrial');
    }
    setPremiumDialogOpen(false);
  };

  if (sessionData) {
    return (
      <Box
        sx={{
          padding: '2rem',
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          position: 'relative', // Asegura que el contenido esté en la capa correcta
          zIndex: 1, // Para mantener el contenido por encima
          '&::before': {
            content: 'none', // Desactiva el pseudoelemento global
          },
          '&::after': {
            content: 'none', // Desactiva el pseudoelemento global
          },
        }}
      >        
        <Box sx={{ textAlign: 'center', marginTop: '1rem', width: '100%', maxWidth: '1200px' }}>
          <Typography variant="body1" sx={{ color: theme.palette.text.primary, fontSize: '1.2rem', marginBottom: '1rem' }}>
            Session authenticated successfully. You can start using the bot!
          </Typography>

          <Box sx={{ width: '100%', marginBottom: '2rem', textAlign: 'left' }}>
            <PendingResponses userId={userSessionId} />
          </Box>

          <Button
            variant="contained"
            onClick={handleModeChange}
            sx={{
              marginTop: '1rem',
              padding: '1rem',
              backgroundColor: theme.palette.primary.main,
              borderRadius: '8px',
              fontSize: '1.2rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {isAutonomous ? 'Disable autonomous mode' : 'Enable autonomous mode'}
          </Button>

          <Button
            variant="outlined"
            onClick={handleLogout}
            sx={{
              margin: '1rem',
              padding: '1rem',
              borderColor: theme.palette.divider,
              borderRadius: '8px',
              fontSize: '1.2rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              color: theme.palette.text.primary,
            }}
          >
            Logout
          </Button>

          <Button
            variant="outlined"
            onClick={handleSendMasive}
            sx={{
              margin: '1rem',
              padding: '1rem',
              borderColor: theme.palette.divider,
              borderRadius: '8px',
              fontSize: '1.2rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              color: theme.palette.text.primary,
            }}
          >
            Send bulk messages
          </Button>
        </Box>

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        {/* Diálogo de suscripción premium */}
        <Dialog
          open={premiumDialogOpen}
          onClose={handlePremiumDialogClose}
          aria-labelledby="premium-dialog-title"
          aria-describedby="premium-dialog-description"
        >
          <DialogTitle id="premium-dialog-title">
            {user.hasUsedFreeTrial ? 'Upgrade to Premium' : 'Start Your Free Trial'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="premium-dialog-description">
              Our premium subscription offers you the ability to send bulk messages, create unlimited sessions, and enjoy priority support.
              {user.hasUsedFreeTrial
                ? 'Subscribe now to unlock all features.'
                : 'Start your free trial today and experience all the features for free for 7 days.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePremiumDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handlePremiumActionClick} color="primary" autoFocus>
              {user.hasUsedFreeTrial ? 'Subscribe' : 'Free Trial'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '2rem', minHeight: '100vh', backgroundColor: theme.palette.background.default, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!showQRCode && (
        <>
          <GoHomeButton />
          <Box sx={{ width: '100%', maxWidth: '600px', textAlign: 'center', marginBottom: '2rem' }}>
            <Typography variant="h4" sx={{ color: theme.palette.text.primary, marginBottom: '1rem' }}>
              Configure your WsBot
            </Typography>

            <TextField
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              fullWidth
              sx={{
                marginBottom: '1.5rem',
                backgroundColor: theme.palette.background.paper,
                borderRadius: '8px',
              }}
            />

            <TextField
              label="Product or Service Details"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
              multiline
              minRows={6}
              fullWidth
              sx={{
                marginBottom: '1.5rem',
                backgroundColor: theme.palette.background.paper,
                borderRadius: '8px',
                maxHeight: '400px',
                overflowY: 'auto',
              }}
            />

            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
              fullWidth
              sx={{
                marginTop: '1.5rem',
                padding: '1rem',
                backgroundColor: theme.palette.primary.main,
                borderRadius: '8px',
                fontSize: '1.2rem',
              }}
            >
              Generate QR for WhatsApp
            </Button>

            <Button
              variant="contained"
              onClick={handleLogout}
              fullWidth
              sx={{
                marginTop: '1.5rem',
                padding: '1rem',
                backgroundColor: theme.palette.secondary.main,
                borderRadius: '8px',
                fontSize: '1.2rem',
              }}
            >
              Cerrar Sesión
            </Button>
          </Box>
        </>
      )}

      {loading ? (
        <CircularProgress sx={{ color: theme.palette.primary.main, marginTop: '2rem' }} />
      ) : showQRCode && qrCodeData && !isAuthenticated ? (
        <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary, marginBottom: '1rem' }}>
            Scan this QR code with your WhatsApp app
          </Typography>
          <QRCodeGenerator value={qrCodeData} size={256} />
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, marginTop: '1rem' }}>
            Waiting for authentication...
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default ConfigQR;
