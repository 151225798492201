// PendingResponses.js
import React, { useEffect, useState, useCallback } from 'react';
import { Typography, CircularProgress, Box, Snackbar, Alert } from '@mui/material';
import ResponseCard from './responseCard'; // Asegúrate de que este es el componente optimizado
import { getPendingResponses } from '../services/client';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Styled Components
const StyledContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxHeight: '80vh', // Ajustado para mayor flexibilidad
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    maxHeight: '70vh',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
}));

const NoResponsesTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(4),
}));

const PendingResponses = ({ userId }) => {
  const [pendingResponses, setPendingResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const fetchPendingResponses = useCallback(async () => {
    try {
      const responses = await getPendingResponses(userId);
      if (Array.isArray(responses)) {
        setPendingResponses(responses);
      } else {
        setPendingResponses([]);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching pending responses:', err);
      setError('Error al obtener las respuestas pendientes.');
      setLoading(false);
      setSnackbar({
        open: true,
        message: 'Error al obtener las respuestas pendientes.',
        severity: 'error',
      });
    }
  }, [userId]);

  useEffect(() => {
    fetchPendingResponses();
    const interval = setInterval(fetchPendingResponses, 30000); // Update every 30 seconds
    return () => clearInterval(interval);
  }, [fetchPendingResponses]);

  const handleMessageDeleted = (deletedId) => {
    setPendingResponses((prevResponses) =>
      prevResponses.filter((response) => response.id !== deletedId)
    );
    setSnackbar({
      open: true,
      message: 'Mensaje eliminado exitosamente.',
      severity: 'success',
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={60} sx={{ color: theme.palette.primary.main }} />
      </LoadingContainer>
    );
  }

  return (
    <>
      <StyledContainer>
        <StyledTypography variant={isSmallScreen ? 'h5' : 'h4'}>
          Respuestas Pendientes
        </StyledTypography>
        {pendingResponses.length === 0 ? (
          <NoResponsesTypography variant="body1">
            No hay respuestas pendientes.
          </NoResponsesTypography>
        ) : (
          pendingResponses.map((response) => (
            <ResponseCard
              key={response.id} // Usar un identificador único en lugar del índice
              userId={userId}
              response={response}
              onMessageDeleted={handleMessageDeleted}
            />
          ))
        )}
      </StyledContainer>

      {/* Snackbar para mostrar mensajes */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PendingResponses;
