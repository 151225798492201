// Login.js
import React, { useState } from 'react';
import { useGlobal } from '../context/globalContex'; // Import the global state
import {
  Button,
  Typography,
  TextField,
  Box,
  Grid2,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { loginUser } = useGlobal(); // Bring in the loginUser function from the global state
  const theme = useTheme(); // Using the global Material-UI theme
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    console.log(`Email: ${email}, Password: ${password}`);

    if (!email || !password) {
      setSnackbar({
        open: true,
        message: 'Please complete all fields.',
        severity: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      await loginUser(email, password); // Use loginUser from the global state to perform login
      setSnackbar({
        open: true,
        message: 'Login successful.',
        severity: 'success',
      });
      setTimeout(() => {
        navigate('/dashboard');
        window.location.reload(); // Redirect to the dashboard page
      }, 1000);
    } catch (error) {
      setLoading(false);
      setSnackbar({
        open: true,
        message:
          error.response?.data?.error || 'Incorrect email or password.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 20px',
        }}
      >
        <Grid2
          container
          maxWidth="600px"
          spacing={2}
          sx={{ textAlign: 'center', color: theme.palette.text.primary }}
        >
          <Grid2 item xs={12}>
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.text.primary,
                fontSize: theme.typography.h3.fontSize,
              }}
            >
              Welcome Back
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.text.secondary,
                marginBottom: '2rem',
              }}
            >
              Log in to access your account.
            </Typography>
            <form onSubmit={handleLogin} autoComplete="off">
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                size="large"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: '1rem' }}
                required
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ marginBottom: '1rem' }}
                required
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disabled={loading}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  marginBottom: '1rem',
                }}
              >
                {loading ? 'Loading...' : 'Log In'}
              </Button>
            </form>
            <Typography
              variant="body1"
              sx={{ margin: '1rem 0', color: theme.palette.text.secondary }}
            >
              or
            </Typography>
            <Button
              size="large"
              onClick={() => navigate('/')}
              fullWidth
              variant="outlined"
              sx={{
                color: theme.palette.secondary.contrastText,
                borderColor: theme.palette.divider,
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              Register
            </Button>
          </Grid2>
        </Grid2>
      </Box>
      <Box
        component="footer"
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.secondary,
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <Typography
          sx={{
            display: 'block',
            marginBottom: '10px',
            color: theme.palette.text.secondary,
          }}
        >
          Powered by Luis Correa
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.secondary,
          }}
        >
          Credits to other developers:{' '}
          <a
            href="https://github.com/some-repo"
            style={{ color: theme.palette.primary.main }}
          >
            GitHub Repo
          </a>
        </Typography>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
