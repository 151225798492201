import React, { useState } from 'react';
import {
  Button,
  Typography,
  TextField,
  Box,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import UserService from '../services/user';

const PasswordRegister = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const email = location.state?.email || ''; // Accede al email desde el estado de navegación
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  const validatePassword = (password) => {
    setPasswordValidation({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setSnackbar({
        open: true,
        message: '¡Las contraseñas no coinciden!',
        severity: 'error',
      });
    } else if (Object.values(passwordValidation).includes(false)) {
      setSnackbar({
        open: true,
        message: 'Por favor, cumple con todos los requisitos de la contraseña.',
        severity: 'error',
      });
    } else {
      try {
        await UserService.register(email, password);
        setSnackbar({
          open: true,
          message: 'Registro exitoso.',
          severity: 'success',
        });
        setTimeout(() => {
          navigate('/verification-notice'); // Redirigir a la página de aviso de verificación
        }, 1000);
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Error en el registro.',
          severity: 'error',
        });
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 20px',
        }}
      >
        <Grid container maxWidth="600px" spacing={2} sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.text.primary,
                fontSize: theme.typography.h3.fontSize,
              }}
            >
              Establece tu Contraseña
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.text.secondary,
                marginBottom: '2rem',
              }}
            >
              Email: {email}
            </Typography>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                type="password"
                label="Contraseña"
                variant="outlined"
                fullWidth
                size="large"
                value={password}
                onChange={handlePasswordChange}
                sx={{ marginBottom: '1rem' }}
              />
              <TextField
                type="password"
                label="Confirmar Contraseña"
                variant="outlined"
                fullWidth
                size="large"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                sx={{ marginBottom: '1rem' }}
              />

              {/* Restricciones dinámicas de la contraseña */}
              <Box
                sx={{
                  textAlign: 'left',
                  marginBottom: '1rem',
                  color: theme.palette.text.secondary,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.length
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                  }}
                >
                  {passwordValidation.length ? '✓' : '⬜'} Al menos 8 caracteres
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.uppercase
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                  }}
                >
                  {passwordValidation.uppercase ? '✓' : '⬜'} Al menos 1 letra
                  mayúscula
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.lowercase
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                  }}
                >
                  {passwordValidation.lowercase ? '✓' : '⬜'} Al menos 1 letra
                  minúscula
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.number
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                  }}
                >
                  {passwordValidation.number ? '✓' : '⬜'} Al menos 1 número
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.specialChar
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                  }}
                >
                  {passwordValidation.specialChar ? '✓' : '⬜'} Al menos 1
                  carácter especial (!@#$%^&*)
                </Typography>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                Registrarse
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="footer"
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.secondary,
          padding: '20px',
        }}
      >
        <Typography
          sx={{
            display: 'block',
            marginBottom: '10px',
            color: theme.palette.text.secondary,
          }}
        >
          Powered by Luis Correa
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.secondary,
          }}
        >
          Créditos a otros desarrolladores:{' '}
          <a
            href="https://github.com/some-repo"
            style={{ color: theme.palette.primary.main }}
          >
            GitHub Repo
          </a>
        </Typography>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PasswordRegister;
