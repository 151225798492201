import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const VerificationSuccess = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: theme.palette.background.default, // Asegúrate de que el tema tenga palette.background.default
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: { xs: '16px', sm: '24px' },
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ textAlign: 'center', color: theme.palette.text.primary }}
      >
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography
            variant={isSmallScreen ? 'h4' : 'h2'}
            sx={{ color: theme.palette.text.primary, marginBottom: '1rem' }}
          >
            ¡Tu correo electrónico ha sido verificado exitosamente!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '1rem', sm: '1.25rem' },
              color: theme.palette.text.secondary,
              marginBottom: '2rem',
            }}
          >
            Ahora puedes iniciar sesión con tu cuenta.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleLoginRedirect}
            fullWidth={isSmallScreen}
            aria-label="Ir a la página de inicio de sesión"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              ':hover': {
                backgroundColor: theme.palette.primary.dark,
              },
              padding: { xs: '12px', sm: '16px' },
              fontSize: { xs: '1rem', sm: '1.125rem' },
            }}
          >
            Ir a Iniciar Sesión
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VerificationSuccess;
