// EmailConfirmation.js
import React, { useEffect, useState } from 'react';
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmailConfirmation = () => {
  const { id } = useParams(); // Extract :id from URL
  const theme = useTheme();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [confirmationStatus, setConfirmationStatus] = useState(null); // 'success' or 'error'
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.post(
          `https://apiwsproject.onrender.com/api/users/verification/${id}`
        );
        if (response.status === 200) {
          setConfirmationStatus('success');
          setSnackbar({
            open: true,
            message: 'Your account has been successfully verified. You can now log in.',
            severity: 'success',
          });
        } else {
          setConfirmationStatus('error');
          setSnackbar({
            open: true,
            message: 'There was an issue verifying your account. Please try again.',
            severity: 'error',
          });
        }
      } catch (error) {
        setConfirmationStatus('error');
        setSnackbar({
          open: true,
          message:
            error.response?.data?.error ||
            'There was an issue verifying your account. Please try again.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      confirmEmail();
    } else {
      setConfirmationStatus('error');
      setSnackbar({
        open: true,
        message: 'Invalid verification link.',
        severity: 'error',
      });
      setLoading(false);
    }
  }, [id]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        padding: '2rem',
      }}
    >
      {loading ? (
        <CircularProgress size={60} sx={{ color: theme.palette.primary.main }} />
      ) : confirmationStatus === 'success' ? (
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor: theme.palette.background.paper,
            padding: '2rem',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            color: theme.palette.text.primary,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Account Verified Successfully!
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your account has been successfully verified. You can now log in to your account.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginRedirect}
            sx={{ marginTop: '1rem' }}
          >
            Go to Login
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor: theme.palette.background.paper,
            padding: '2rem',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            color: theme.palette.text.primary,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Verification Failed
          </Typography>
          <Typography variant="body1" gutterBottom>
            There was an issue verifying your account. Please try again or contact support.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginRedirect}
            sx={{ marginTop: '1rem' }}
          >
            Go to Login
          </Button>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmailConfirmation;
