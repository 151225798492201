// EmailVerificationNotice.js
import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

const StyledButtonContained = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5, 3),
  fontSize: '1rem',
  fontWeight: 500,
  boxShadow: theme.shadows[3],
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[4],
  },
}));

const StyledButtonOutlined = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(1.5, 3),
  fontSize: '1rem',
  fontWeight: 500,
  boxShadow: theme.shadows[1],
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',

  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    boxShadow: theme.shadows[2],
  },
}));

const StyledFooter = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.secondary,
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const EmailVerificationNotice = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  
  const [loading, setLoading] = useState(false);

  const handleResendEmail = async () => {
    try {
      setLoading(true);
      // Aquí puedes llamar a una función para reenviar el correo de verificación
      // Por ejemplo: await UserService.resendVerificationEmail();
      console.log('Resend email clicked');
      
      // Simulación de envío exitoso
      setTimeout(() => {
        setLoading(false);
        setSnackbar({
          open: true,
          message: 'Correo de verificación reenviado exitosamente.',
          severity: 'success',
        });
      }, 2000);
    } catch (error) {
      setLoading(false);
      setSnackbar({
        open: true,
        message: 'Error al reenviar el correo de verificación.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <StyledContainer>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <StyledGrid item xs={12} md={8} lg={6}>
          <StyledTypography variant="h3">
            Confirm Your Email Address
          </StyledTypography>
          <SubtitleTypography variant="body1">
            We have sent you an email with a link to confirm your account. Please
            check your inbox to complete the registration process.
          </SubtitleTypography>
          <Button
            variant="contained"
            size="large"
            onClick={handleResendEmail}
            disabled={loading}
            aria-label="Resend Confirmation Email"
            sx={{ width: '100%' }}
          >
            {loading ? (
              <>
                <CircularProgress size={24} sx={{ color: theme.palette.primary.contrastText, marginRight: theme.spacing(1) }} />
                Resending...
              </>
            ) : (
              'Resend Confirmation Email'
            )}
          </Button>
          <Button
            variant="outlined"
            size="large"
            onClick={() => navigate('/login')}
            aria-label="Go to Login"
            sx={{ width: '100%' }}
          >
            Go to Login
          </Button>
        </StyledGrid>
      </Grid>
      <StyledFooter>
        <Typography
          sx={{
            display: 'block',
            marginBottom: theme.spacing(1),
            color: theme.palette.text.secondary,
          }}
        >
          Powered by Luis Correa
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.secondary,
          }}
        >
          Credits to other developers:{' '}
          <a
            href="https://github.com/some-repo"
            style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="GitHub Repo"
          >
            GitHub Repo
          </a>
        </Typography>
      </StyledFooter>

      {/* Snackbar para mostrar mensajes de éxito o error */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default EmailVerificationNotice;
