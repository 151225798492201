// Home.js
import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Grid2,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleSignUp = () => {
    if (email) {
      navigate('/register-password', { state: { email } });
    } else {
      alert('Please enter your email address');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid2
          container
          sx={{
            width: '100%',
            maxWidth: '1200px',
            textAlign: 'left',
            padding: '0 50px',
            color: theme.palette.text.primary,
          }}
          alignItems="center"
        >
          <Grid2
            item
            xs={12}
            sm={12}
            md={14}
            lg={16}
            xl={16}
            sx={{
              textAlign: 'left',
              padding: '0 20px',
              color: theme.palette.text.primary,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.text.primary,
                fontSize: theme.typography.h3.fontSize,
              }}
            >
              Let’s build from here
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.text.secondary,
                display: 'block',
                marginBottom: '2rem',
              }}
            >
              The world’s leading AI-powered developer platform.
            </Typography>
            <Grid2 container spacing={2}>
              <Grid2 item xs={12} sm={8}>
                <TextField
                  placeholder="you@company.com"
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    style: {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    height: '100%',
                  }}
                  onClick={handleSignUp}
                >
                  Sign up for GitHub
                </Button>
              </Grid2>
            </Grid2>
            <Grid2
              container
              alignItems="center"
              sx={{ marginTop: '1rem' }}
              spacing={2}
            >
              <Grid2 item>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  or
                </Typography>
              </Grid2>
              <Grid2 item>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => navigate('/login')}
                  sx={{
                    color: theme.palette.secondary.contrastText,
                    borderColor: theme.palette.divider,
                    backgroundColor: theme.palette.secondary.main,
                  }}
                >
                  Sign in
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
      <Box
        component="footer"
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.secondary,
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <Typography
          sx={{
            display: 'block',
            marginBottom: '10px',
            color: theme.palette.text.secondary,
          }}
        >
          Powered by Luis Correa
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.secondary,
          }}
        >
          Credits to other developers:{' '}
          <a
            href="https://github.com/some-repo"
            style={{ color: theme.palette.primary.main }}
          >
            GitHub Repo
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
