import axios from 'axios';

// const API_URL = 'http://localhost:4001/api/sessions'; // Asegúrate de cambiar la URL según tu entorno
const API_URL = 'https://apiwsproject-latest.onrender.com/api/sessions'; // Asegúrate de cambiar la URL según tu entorno

const SessionService = {
  
  // Crear una nueva sesión
  createSession: async (sessionData) => {
    try {
      const response = await axios.post(API_URL, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Obtener todas las sesiones
  getAllSessions: async () => {
    try {
      const response = await axios.get(API_URL);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Obtener una sesión por userId
  getSessionByUserId: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/${userId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Actualizar una sesión por ID
  updateSession: async (id, sessionData) => {
    try {
      const response = await axios.put(`${API_URL}/${id}`, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Eliminar una sesión por ID
  deleteSession: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },
};

export default SessionService;
