import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    mode: 'dark',
    secondary: {
      main: '#7986cb',
      light: '#aab6fe',
      dark: '#49599a',
      contrastText: '#ffffff',
    },
    primary: {
      main: '#7a3eea',  // El morado que quieres
      light: '#b084f5',
      dark: '#5a2bb3',
      contrastText: '#ffffff',  // El texto será blanco para un mejor contraste
    },    
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 'clamp(2.5rem, 8vw, 6rem)',
      fontWeight: 300,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontSize: 'clamp(2rem, 6vw, 3.75rem)',
      fontWeight: 300,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontSize: 'clamp(1.75rem, 4vw, 3rem)',
      fontWeight: 400,
      letterSpacing: '0em',
    },
    h4: {
      fontSize: 'clamp(1.5rem, 3vw, 2.125rem)',
      fontWeight: 400,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontSize: 'clamp(1.25rem, 2vw, 1.5rem)',
      fontWeight: 400,
      letterSpacing: '0em',
    },
    h6: {
      fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
      fontWeight: 500,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontSize: 'clamp(0.875rem, 1.5vw, 1rem)',
      fontWeight: 400,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontSize: 'clamp(0.75rem, 1.25vw, 0.875rem)',
      fontWeight: 400,
      letterSpacing: '0.01071em',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          textTransform: 'none',
          fontSize: 'clamp(0.8125rem, 1.5vw, 0.875rem)',
          padding: '6px 16px',
          transition: '0.3s',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          },
        },
        sizeLarge: {
          fontSize: 'clamp(0.9375rem, 1.75vw, 1rem)',
          padding: '8px 22px',
        },
        sizeSmall: {
          fontSize: 'clamp(0.75rem, 1.25vw, 0.8125rem)',
          padding: '4px 10px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          transition: '0.3s',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 24px rgba(0,0,0,0.2)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
              transition: '0.3s',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#7986cb',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#121212', 0.8),
          backdropFilter: 'blur(8px)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1e1e1e',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: 'clamp(1rem, 2vw, 1.5rem)',
        },
      },
    },
  },
});

// Aplica estilos de fuente responsivos
theme = responsiveFontSizes(theme);


export default theme;
