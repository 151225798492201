import React, { useState } from 'react';
import {
  Button,
  Typography,
  Snackbar,
  Alert,
  Container,
  Box,
  Paper,
} from '@mui/material';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { enviarMasivoCSV } from '../services/client';
import GoBackButton from './goBackButton';

const SendBulkMessages = () => {
  const [file, setFile] = useState(null);
  const location = useLocation();
  const userSessionId = location.state?.userSessionId;

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleUploadChange = (event) => {
    const fileList = event.target.files;
    if (fileList.length > 0) {
      const latestFile = fileList[fileList.length - 1];
      setFile(latestFile);
      setSnackbar({ open: true, message: `${latestFile.name} file selected successfully`, severity: 'success' });
    } else {
      setFile(null);
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      setSnackbar({ open: true, message: 'Please select a CSV file before submitting.', severity: 'error' });
      return;
    }
    try {
      await enviarMasivoCSV(userSessionId, file);
      setSnackbar({ open: true, message: 'Messages sent successfully!', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: `Failed to send messages: ${error.message}`, severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.default',
        padding: 4,
        maxWidth: '100%',
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      <GoBackButton />
      <Typography variant="h3" color="textPrimary" align="center" sx={{ fontSize: { xs: '2rem', sm: '2.5rem' }, mb: 4 }}>
        Send Bulk WhatsApp Messages
      </Typography>

      {/* Caja con scroll solo para móviles */}
      <Box
        sx={{
          textAlign: 'left',  // Alineación a la izquierda
          color: 'text.secondary',
          mb: 4,
          maxWidth: { xs: '100%', sm: '80%' },
          maxHeight: { xs: '400px', sm: 'none' },  // Limita la altura en pantallas pequeñas
          overflowY: { xs: 'auto', sm: 'visible' },  // Scroll solo en móviles
          paddingRight: { xs: '1rem' },  // Espaciado para evitar cortar el texto con el scroll
        }}
      >
        <Typography variant="body1" paragraph textAlign="left">
          Follow these steps to use the bot effectively:
        </Typography>
        <Typography variant="body2" paragraph textAlign="left">
          1. Download the sample CSV file.
          <Button
            variant="contained"
            sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', ml: 1 }}
            href="/path-to-sample.csv"
            download="sample.csv"
          >
            Download Sample
          </Button>
        </Typography>
        <Typography variant="body2" paragraph textAlign="left">
          2. Ensure all phone numbers include the international country code, just like in the example file.
          <br />
          3. Write the message you want to send to your users. We recommend using polite, non-aggressive messages that end with a simple question.
          <br />
          4. Save the file in CSV format (comma-separated).
          <br />
          5. Upload the CSV file to the platform.
          <br />
          6. Click the "Send Bulk Messages" button.
          <br />
          7. Once you see the "Messages sent successfully" notification, you can return to the previous screen to monitor user responses.
        </Typography>
        <Box sx={{ mt: 2, textAlign: 'left', maxWidth: '100%' }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Example CSV file format:
          </Typography>
          <Paper
            elevation={2}
            sx={{ padding: 2, bgcolor: 'background.paper', overflowX: 'auto', whiteSpace: 'pre-wrap' }}
          >
            <pre>
              Phone Number,Message{'\n'}
              34612345678,Hello, how are you?{'\n'}
              447911123456,Good morning, how can I assist you?
            </pre>
          </Paper>
        </Box>
      </Box>

      <Box sx={{ width: '100%', maxWidth: 400, mb: 2 }}>
        <label htmlFor="contained-button-file">
          <input
            accept=".csv"
            id="contained-button-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleUploadChange}
          />
          <Button
            variant="contained"
            component="span"
            fullWidth
            startIcon={<UploadFileIcon />}
            sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}
          >
            Select CSV File
          </Button>
        </label>
        {file && (
          <Typography color="textPrimary" sx={{ mt: 2, wordWrap: 'break-word' }}>
            {file.name}
          </Typography>
        )}
      </Box>

      <Button
        variant="contained"
        size="large"
        fullWidth
        onClick={handleSubmit}
        sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', mt: 4, maxWidth: 400 }}
      >
        Send Bulk Messages
      </Button>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SendBulkMessages;
