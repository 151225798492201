import React, { createContext, useContext, useReducer } from 'react';
import UserService from '../services/user';

const GlobalContext = createContext();

const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'LOGOUT_USER':
      return { ...state, user: null };
    case 'ADD_USER_SESSION':
      return { 
        ...state, 
        user: {
          ...state.user,
          sessions: [...(state.user.sessions || []), action.payload]
        }
      };
    case 'SET_API_KEY':
      return {
        ...state,
        user: {
          ...state.user,
          openApiKeyHash: action.payload // Actualizamos la API Key encriptada en el estado global
        }
      };
    default:
      return state;
  }
};

export const GlobalProvider = ({ children }) => {
  const initialState = {
    user: null,
  };

  const [state, dispatch] = useReducer(globalReducer, initialState);

  const loginUser = async (email, password) => {
    try {
      const response = await UserService.login(email, password);
      dispatch({ type: 'SET_USER', payload: response.user });
      console.log('Login successful:', response);
    } catch (error) {
      console.error('Error en loginUser:', error);
      throw error;
    }
  };

  const logoutUser = async () => {
    try {
      await UserService.logout();
      dispatch({ type: 'LOGOUT_USER' });
    } catch (error) {
      console.error('Error en logoutUser:', error);
    }
  };

  const addUserSession = (newSession) => {
    dispatch({ type: 'ADD_USER_SESSION', payload: newSession });
  };

  // Función para guardar la API Key
  const saveUserApiKey = async (userId, apiKey) => {
    try {
      const response = await UserService.saveApiKey(userId, apiKey);
      dispatch({ type: 'SET_API_KEY', payload: response.encryptedApiKey }); // Actualizamos el estado global con la API Key encriptada
      console.log('API Key saved successfully:', response);
    } catch (error) {
      console.error('Error guardando la API Key:', error);
      throw error;
    }
  };

  return (
    <GlobalContext.Provider value={{ state, dispatch, loginUser, logoutUser, addUserSession, saveUserApiKey }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
