import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobal } from '../context/globalContex';
import { useTheme } from '@mui/material/styles';
import UserSessionService from '../services/userSessions';

const SessionsComponent = () => {
  const { state, addUserSession } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();
  const theme = useTheme();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);

  const handleCreateUserSession = async () => {
    if (!user.activeSus && user.sessions.length >= 1) {
      setPremiumDialogOpen(true);
      return;
    }

    try {
      const newUserSession = await UserSessionService.createSession({
        userId: user._id,
      });
      console.log('newUserSession:', newUserSession);

      addUserSession(newUserSession);

      setSnackbar({
        open: true,
        message: 'New session created successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error creating the session',
        severity: 'error',
      });
      console.error('Error creating the session:', error);
    }
  };

  const handleCardClick = (userSessionId) => {
    navigate(`/selectBot`, { state: { userSessionId } });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePremiumDialogClose = () => {
    setPremiumDialogOpen(false);
  };

  const handlePremiumActionClick = () => {
    if (user.hasUsedFreeTrial) {
      navigate('/subscribe');
    } else {
      navigate('/freeTrial');
    }
    setPremiumDialogOpen(false);
  };

  return (
    <Box sx={{ padding: { xs: '10px', sm: '20px' }, overflow: 'hidden' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateUserSession}
            startIcon={<AddIcon />}
            sx={{ 
              marginBottom: '16px',
              width: { xs: '100%', sm: 'auto' },
              padding: { xs: '10px', sm: '6px 16px' },
              minHeight: '48px',
            }}
          >
            Create New Session
          </Button>
        </Grid>

        {user.sessions && user.sessions.length > 0 ? (
          user.sessions.map((userSession) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={userSession._id}>
              <Card
                onClick={() => handleCardClick(userSession._id)}
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  '-webkit-line-clamp': '3',
                  '-webkit-box-orient': 'vertical',
                  lineHeight: '1.5em',
                  maxHeight: '4.5em', // 3 líneas de texto
                }}
              >
                <CardContent sx={{ 
                  flexGrow: 1, 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  padding: '16px',
                }}>
                  <Typography
                    variant="h6"
                    component="div"
                    color="text.primary"
                    align="center"
                    sx={{
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {userSession._id || 'Session Name'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              No active sessions.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <Dialog
        open={premiumDialogOpen}
        onClose={handlePremiumDialogClose}
        aria-labelledby="premium-dialog-title"
        aria-describedby="premium-dialog-description"
      >
        <DialogTitle id="premium-dialog-title">
          {user.hasUsedFreeTrial ? 'Upgrade to Premium' : 'Start Your Free Trial'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="premium-dialog-description">
            Our premium subscription offers you the ability to create unlimited sessions, priority support, and access to exclusive features. 
            {user.hasUsedFreeTrial
              ? 'Subscribe now to enjoy all the benefits.'
              : 'Start your free trial today and experience all the features for free for 7 days.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePremiumDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePremiumActionClick} color="primary" autoFocus>
            {user.hasUsedFreeTrial ? 'Subscribe' : 'Free Trial'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SessionsComponent;