// ResponseCard.js
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles'; // We use the Material-UI theme
import { sendValidatedResponse, deleteMessage } from '../services/client';

const ResponseCard = ({ userId, response, onMessageDeleted }) => {
  const theme = useTheme(); // Access the Material-UI theme
  const [editedResponse, setEditedResponse] = useState(
    response.gptResponse || 'GPT response'
  );
  const [isEditing, setIsEditing] = useState(false); // Controls if we are in edit mode
  const [sending, setSending] = useState(false); // Controls the sending state

  const handleApprove = async () => {
    setSending(true);
    try {
      await sendValidatedResponse(userId, editedResponse, response.id);
      setSending(false);
    } catch (error) {
      console.error('Error sending approved response:', error);
      setSending(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true); // Activate edit mode
  };

  const handleSaveEdit = async () => {
    setSending(true);
    try {
      await sendValidatedResponse(userId, editedResponse, response.id);
      setIsEditing(false); // Deactivate edit mode
      setSending(false);
    } catch (error) {
      console.error('Error sending edited response:', error);
      setSending(false);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false); // Just deactivate edit mode
  };

  const handleDelete = async () => {
    setSending(true);
    try {
      await deleteMessage(response.id);
      setSending(false);
      if (onMessageDeleted) {
        onMessageDeleted(response.id); // Notify the parent component
      }
    } catch (error) {
      console.error('Error deleting message:', error);
      setSending(false);
    }
  };

  const originalMessageText =
    response.userResponse?.body || 'Message not available';
  const mobileNumber = response.userResponse?.from || 'Number not available';
  const conversation = response.summarizedHistory || 'Call summary';

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 3,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        borderColor: theme.palette.divider,
      }}
    >
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Conversation Summary:
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: theme.palette.text.primary }}
        >
          {conversation}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Mobile:
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: theme.palette.text.primary }}
        >
          {mobileNumber}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          User Message:
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: theme.palette.text.primary }}
        >
          {originalMessageText}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Generated Response:
        </Typography>

        {isEditing ? (
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={editedResponse}
            onChange={(e) => setEditedResponse(e.target.value)}
            sx={{
              backgroundColor: theme.palette.background.default,
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
        ) : (
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: theme.palette.text.primary }}
          >
            {editedResponse}
          </Typography>
        )}

        <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
          {isEditing ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveEdit}
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Save and Send'}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelEdit}
                disabled={sending}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApprove}
                disabled={sending}
              >
                {sending ? 'Sending...' : 'OK'}
              </Button>
              <Button
                variant="outlined"
                color="default"
                onClick={handleEdit}
                disabled={sending}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={sending}
              >
                {sending ? 'Deleting...' : 'Do Not Reply'}
              </Button>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ResponseCard;
